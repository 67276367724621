import i18next from 'i18next';
import Backend from 'i18next-http-backend';
export const initI18next = () => {
    const lang = document.querySelector("html")?.getAttribute("lang") ?? 'fr';
    return i18next
        .use(Backend)
        .init({
        fallbackLng: 'fr',
        load: 'languageOnly',
        backend: {
            loadPath: `/api/translation/${lang}`,
        }
    });
};
export default i18next;
