import { initI18next } from './typescript/translations/i18n';
initI18next()
    .then()
    .finally(() => {
    Promise.allSettled([
        import("./typescript/class/Comparator"),
        import("./typescript/form"),
        import("./typescript/cmsDesignHandler"),
        import("./typescript/class/security/Login"),
        import("./typescript/class/product/Show"),
        import("./typescript/class/delivery/DeliveryPoint"),
        import("./typescript/class/cart/Cart"),
        import("./typescript/class/cloudinary/UrlImageGenerator"),
        import("./typescript/class/vto/VTOModalHandler"),
        import("./typescript/class/vto/RenderingVTO"),
        import("./typescript/class/lensSelector/LensSelector"),
        import("./typescript/class/product/YouMayAlsoLike"),
        import("./typescript/class/product/listing/Listing"),
        import("./typescript/class/FaceShapeIframe")
    ]);
});
